import * as React from 'react';
import Typography from '@components/Typography';
import Plan from '../Plan';
import './PricingPlan.scss';

const PricingPlan: React.FC = () => {
  return (
    <>
      <Typography
        className="TDB-PricingPlan__description"
        fontSize="subheading-3"
        fontWeight="medium"
        color="neutral-600"
        align="center"
        as="p"
      >
        For individuals, universities, and organizations. Manage complex data and conduct advanced compute at any scale while organizing
        your users into teams.
      </Typography>
      <div className="TDB-PricingPlan__main">
        <Plan
          title="Individual"
          description="For citizen scientists, solo users, individual students and researchers."
          pricing="Free"
          credits="$100 in free credits, for 6 months"
          seats="1 seat"
          variant="primary"
          features={[
            {
              active: true,
              text: 'All TileDB Features',
              key: 'basic-1',
              subFeatures: [
                {
                  active: true,
                  text: 'Asset Management',
                  key: 'sub-basic-1',
                },
                {
                  active: true,
                  text: 'Serverless compute',
                  key: 'sub-basic-2',
                },
                {
                  active: true,
                  text: 'Apps - including notebooks and dashboards',
                  key: 'sub-basic-3',
                },
                {
                  active: true,
                  text: 'Distributed computing via task graphs',
                  key: 'sub-basic-4',
                },
                {
                  active: true,
                  text: 'Access control',
                  key: 'sub-basic-5',
                },
                {
                  active: true,
                  text: 'Logging',
                  key: 'sub-basic-6',
                },
                {
                  active: true,
                  text: 'Academy',
                  key: 'sub-basic-7',
                },
                {
                  active: true,
                  text: 'Marketplace',
                  key: 'sub-basic-8',
                },
              ],
            },
            {
              active: false,
              text: 'Organizations',
              key: 'basic-2',
              tooltip:
                'An organization is a sharing and permissions function in the TileDB. An organization allows you to manage access controls for groups of users, mapping permissions to the way your organization already works. This group of users can be individuals from your own company or even collaborators outside your own company, that you can invite to any TileDB asset such as notebooks, models, arrays, dashboards, task graphs and serverless code.',
            },
            {
              active: false,
              key: 'basic-3',
              text: 'Advanced support',
            },
            {
              active: false,
              key: 'basic-4',
              text: 'Self-hosting option',
            },
            {
              active: false,
              key: 'basic-6',
              text: 'SSO',
            },
          ]}
          renderSignupButton
          buttonText="Sign up now"
        />
        <Plan
          title="University"
          description="Institutions of higher learning, buying for a lab or team of researchers or students."
          pricing="Free"
          credits="$10K of free credits annually"
          seats="20 seats"
          variant="secondary"
          features={[
            {
              active: true,
              text: 'All TileDB Features',
              key: 'basic-1',
              subFeatures: [
                {
                  active: true,
                  text: 'Asset Management',
                  key: 'sub-basic-1',
                },
                {
                  active: true,
                  text: 'Serverless compute',
                  key: 'sub-basic-2',
                },
                {
                  active: true,
                  text: 'Apps - including notebooks and dashboards',
                  key: 'sub-basic-3',
                },
                {
                  active: true,
                  text: 'Distributed computing via task graphs',
                  key: 'sub-basic-4',
                },
                {
                  active: true,
                  text: 'Access control',
                  key: 'sub-basic-5',
                },
                {
                  active: true,
                  text: 'Logging',
                  key: 'sub-basic-6',
                },
                {
                  active: true,
                  text: 'Academy',
                  key: 'sub-basic-7',
                },
                {
                  active: true,
                  text: 'Marketplace',
                  key: 'sub-basic-8',
                },
              ],
            },
            {
              active: true,
              text: 'Organizations',
              key: 'basic-2',
              tooltip:
                'An organization is a sharing and permissions function in the TileDB. An organization allows you to manage access controls for groups of users, mapping permissions to the way your organization already works. This group of users can be individuals from your own company or even collaborators outside your own company, that you can invite to any TileDB asset such as notebooks, models, arrays, dashboards, task graphs and serverless code.',
            },
            {
              active: false,
              key: 'basic-3',
              text: 'Advanced support',
            },
            {
              active: false,
              key: 'basic-4',
              text: 'Self-hosting option',
            },
            {
              active: false,
              key: 'basic-5',
              text: 'Monetize on marketplace',
            },
            {
              active: false,
              key: 'basic-6',
              text: 'SSO',
            },
          ]}
          renderSignupButton={false}
          buttonText="Apply now"
        />
        <Plan
          title="Organization"
          description="Enjoy scalability and secure collaboration."
          pricing="Custom"
          credits="Custom annual credits"
          seats="Custom number of seats"
          variant="tertiary"
          features={[
            {
              active: true,
              text: 'All TileDB Features',
              key: 'basic-1',
              subFeatures: [
                {
                  active: true,
                  text: 'Asset Management',
                  key: 'sub-basic-1',
                },
                {
                  active: true,
                  text: 'Serverless compute',
                  key: 'sub-basic-2',
                },
                {
                  active: true,
                  text: 'Apps - including notebooks and dashboards',
                  key: 'sub-basic-3',
                },
                {
                  active: true,
                  text: 'Distributed computing via task graphs',
                  key: 'sub-basic-4',
                },
                {
                  active: true,
                  text: 'Access control',
                  key: 'sub-basic-5',
                },
                {
                  active: true,
                  text: 'Logging',
                  key: 'sub-basic-6',
                },
                {
                  active: true,
                  text: 'Academy',
                  key: 'sub-basic-7',
                },
                {
                  active: true,
                  text: 'Marketplace',
                  key: 'sub-basic-8',
                },
              ],
            },
            {
              active: true,
              text: 'Organizations',
              key: 'basic-2',
              tooltip:
                'An organization is a sharing and permissions function in the TileDB. An organization allows you to manage access controls for groups of users, mapping permissions to the way your organization already works. This group of users can be individuals from your own company or even collaborators outside your own company, that you can invite to any TileDB asset such as notebooks, models, arrays, dashboards, task graphs and serverless code.',
            },
            {
              active: true,
              key: 'basic-3',
              text: 'Advanced support',
            },
            {
              active: true,
              key: 'basic-4',
              text: 'Self-hosting option',
            },
            {
              active: true,
              key: 'basic-5',
              text: 'Monetize on marketplace',
            },
            {
              active: true,
              key: 'basic-6',
              text: 'SSO',
            },
          ]}
          renderSignupButton={false}
          buttonText="Book a discovery call"
        />
      </div>
    </>
  );
};

export default PricingPlan;
