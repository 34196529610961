import React from 'react';
import classNames from 'classnames';
import { AnchorLink } from 'gatsby-plugin-anchor-links';

import Button from '@components/Button';
import Tooltip from '@components/Tooltip';
import Typography from '@components/Typography';
import links from '@constants/links';
import { PRICING_TABS } from '@constants/PricingTabsTypes';

import { BoxIcon, CancelIcon, CheckIcon, InformationIcon, OrganizationIcon, SeatsIcon, UniversityIcon, UserIcon } from './assets/icons';

import './Plan.scss';

type VariantType = 'primary' | 'secondary' | 'tertiary';

interface Feature {
  active: boolean;
  text: string;
  tooltip?: React.ReactNode;
  subFeatures?: Feature[];
  key: string;
}

interface Props {
  title: string;
  description: string;
  pricing: string;
  pricingPrefix?: string;
  credits: string;
  seats?: string;
  seatSubtitle?: string;
  renderSignupButton: boolean;
  features: Feature[];
  variant: VariantType;
  buttonText: string;
}

const variantIconMap: Record<VariantType, React.ReactNode> = {
  primary: <UserIcon />,
  secondary: <UniversityIcon />,
  tertiary: <OrganizationIcon />,
};

const FeatureContainer: React.FC<Feature> = ({ key, active, text, tooltip, subFeatures }) => (
  <React.Fragment key={key}>
    <div className="TDB-Plan__feature-container">
      {active ? (
        <div className="TDB-Plan__check-icon-wrapper">
          <CheckIcon />
        </div>
      ) : (
        <CancelIcon />
      )}
      <div className="TDB-Plan__feature-text">{text}</div>
      {tooltip && (
        <Tooltip content={tooltip}>
          <InformationIcon />
        </Tooltip>
      )}
    </div>
    {subFeatures && (
      <div className="TDB-Plan__sub-feature">
        {subFeatures.map((subFeature) => (
          <FeatureContainer {...subFeature} key={subFeature.key} />
        ))}
      </div>
    )}
  </React.Fragment>
);

const Plan: React.FC<Props> = ({
  title,
  description,
  pricing,
  pricingPrefix,
  credits,
  seats,
  seatSubtitle,
  renderSignupButton,
  features,
  variant,
  buttonText,
}) => (
  <div className="TDB-Plan-container">
    <div className={`TDB-Plan TDB-Plan-gradient--${variant}`}>
      <div className={`TDB-Plan-gradient-s-${variant}`}>
        <Typography
          as="h5"
          fontSize="heading-4"
          fontWeight="semi-bold"
          color="neutral-600"
          className={`TDB-Plan__title TDB-Plan__title--${variant}`}
        >
          {title}
        </Typography>
        <Typography className="TDB-Plan__description" as="p" fontSize="body" fontWeight="medium" color="neutral-600">
          {description}
        </Typography>
        <Typography className="TDB-Plan__pricing" as="h4" fontSize="heading-3" fontWeight="bold" color="neutral-900">
          {pricingPrefix ? (
            <Typography fontWeight="semi-bold" className="TDB-Plan__pricing-prefix" color="neutral-600">
              {pricingPrefix}
            </Typography>
          ) : null}
          {pricing}
        </Typography>
        <div className={`TDB-Plan__infobox TDB-Plan__infobox--${variant}`}>
          <BoxIcon />
          <Typography fontSize="body" fontWeight="medium" color="neutral-900">
            {credits}
          </Typography>
        </div>

        {seats ? (
          <>
            <div className={`TDB-Plan__infobox TDB-Plan__infobox--top-margin TDB-Plan__infobox--${variant}`}>
              <SeatsIcon />
              <Typography fontSize="body" fontWeight="medium" color="neutral-900">
                {seats}
              </Typography>
            </div>
            {seatSubtitle && (
              <Typography as="p" className="TDB-Plan__seat-subtitle" fontWeight="medium" color="neutral-600">
                {seatSubtitle}
              </Typography>
            )}
          </>
        ) : null}

        <div className="TDB-Plan__divider TDB-Plan__divider--top-margin" />

        <div className="TDB-Plan__divider TDB-Plan__divider--bottom-margin" />

        <div className="TDB-Plan__footer">
          {renderSignupButton ? (
            <Button block primary className={`TDB-Plan__footer__button-${variant}`} to={links.console.signup}>
              {buttonText}
            </Button>
          ) : (
            <Button block primary className={`TDB-Plan__footer__button-${variant}`} to={links.contactSales}>
              {buttonText}
            </Button>
          )}
        </div>
        <div className="TDB-Plan__main">
          {features.map((feature) => (
            <div
              key={feature.key}
              className={classNames('TDB-Plan__feature', {
                'TDB-Plan__feature--active': feature.active,
                [`TDB-Plan__feature--${variant}`]: variant,
              })}
            >
              <FeatureContainer {...feature} />
            </div>
          ))}
        </div>
      </div>
      <div className="TDB-Plan__icon-wrapper-absolute">
        <div className="TDB-Plan__icon-container-relative">
          <div className={`TDB-Plan__card-icon TDB-Plan__card-icon--${variant}`}>{variantIconMap[variant]}</div>
        </div>
      </div>
    </div>
  </div>
);

export default Plan;
